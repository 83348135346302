import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { colors, H2White, H3White, medWrapper } from "../../styles/helpers"

import greenStripe from "../../images/green-stripe.png"

const settings = {
  customPaging: function (i) {
    return (
      <button>
        <span indexdot={i} className="dot-span" />
      </button>
    )
  },
  dots: true,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
}

const Milestones = ({ data }) => {
  return (
    <StyledSection id="milestones">
      <div className="wrapper">
        <div className="title">
          <h2>{data.projectMilestonesTitle}</h2>
        </div>
        <Slider className="steps" {...settings}>
          {data.projectMilestonesSteps.map((step, index) => {
            const imgDisplay = getImage(
              step.image.localFile.childImageSharp.gatsbyImageData
            )
            const imgAlt = step.image.altText
            return (
              <div className="slide" key={index}>
                <div className="slide__content">
                  <div>
                    <h3>{step.title}</h3>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: step.content }} />
                </div>
                <div className="slide__image">
                  <GatsbyImage
                    image={imgDisplay}
                    alt={imgAlt}
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      <div className="bg-graphic" />
    </StyledSection>
  )
}

const StyledSection = styled.section`
  position: relative;
  background-color: ${colors.colorSecondary};
  padding-top: 5rem;

  @media (min-width: 768px) {
    padding-bottom: 5rem;
  }

  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    margin-bottom: 5rem;

    h2 {
      ${H2White};
    }
  }

  .bg-graphic {
    position: absolute;
    top: -5rem;
    left: -10%;
    padding-bottom: 15rem;
    width: 120%;
    background-image: url(${greenStripe});
    background-size: 100% 100%;
    background-repeat: repeat-x;
    background-position: bottom center;
    z-index: -1;
  }

  .steps {
    position: relative;
    width: calc(100%);
    max-width: 100%;
    z-index: 10;
    background-color: transparent;

    @media (min-width: 768px) {
      width: calc(100%);
      padding-top: 10rem;
      background-color: transparent;
    }

    .slick-dots {
      display: none !important;
      top: 0;
      bottom: auto;

      @media (min-width: 768px) {
        display: block !important;
      }

      li {
        width: 3rem;
        height: 3rem;

        button {
          display: block;
          position: relative;
          width: 3rem;
          height: 3rem;

          &::before {
            width: 3rem;
            height: 3rem;
            color: ${colors.colorPrimary};
            font-size: 1.5rem;
            opacity: 1;
          }

          .dot-span {
            display: block;
            position: absolute;
            top: 0.85rem;
            right: 45%;
            width: 3.75rem;
            height: 1px;
            background-color: ${colors.colorPrimary};

            &[indexdot="0"] {
              display: none;
            }
          }
        }

        &.slick-active {
          z-index: 100;

          button {
            z-index: 100;

            .dot-span {
              z-index: -1;
            }

            &::before {
              color: ${colors.white};
              font-size: 2.5rem;
            }
          }
        }
      }
    }

    .slick-arrow {
      top: 50%;
      width: 5rem;
      height: 5rem;
      z-index: 1000;
      background-color: ${colors.colorTertiary};

      @media (min-width: 768px) {
        top: 0;
      }

      &::before {
        font-family: "FontAwesome" !important;
      }

      &.slick-prev {
        left: 0;

        &::before {
          content: "\f053";
        }
      }

      &.slick-next {
        right: 0;

        &::before {
          content: "\f054";
        }
      }
    }

    .slide {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      &__content {
        width: 100%;
        padding: 2rem;

        @media (min-width: 768px) {
          width: calc(50%);
          padding: 0;
          padding-right: 7.5rem;
        }

        h3 {
          ${H2White};
        }

        p {
          ${H3White};
          font-weight: 300;
        }
      }

      &__image {
        width: 100%;

        @media (min-width: 768px) {
          border-left: 0.2rem solid ${colors.white};
          width: calc(50%);
          padding-left: 5rem;
        }
      }
    }
  }
`

export default Milestones
