import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1White, colors, H2White, medWrapper } from "../../styles/helpers"

const MoreInformation = ({ data }) => {
  const imgDisplay = getImage(
    data.moreInformationImage.localFile.childImageSharp.gatsbyImageData
  )
  const imgAlt = data.moreInformationImage.altText
  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="content">
          <div className="content__title">
            <h2>{data.moreInformationTitle}</h2>
          </div>
          <div
            className="content__content"
            dangerouslySetInnerHTML={{ __html: data.moreInformationContent }}
          />
          <div className="content__links">
            {data.moreInformationLinks.map((link, index) => {
              return (
                <StyledLink key={index}>
                  <a target="_blank" rel="noreferrer" href={link.url}>
                    {link.title}
                  </a>
                </StyledLink>
              )
            })}
          </div>
        </div>
      </div>
      <div className="image">
        <GatsbyImage
          image={imgDisplay}
          alt={imgAlt}
          layout="fullWidth"
          formats={["auto", "webp", "avif"]}
        />
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  .wrapper {
    ${medWrapper};
    position: relative;
    z-index: 10;
  }

  .content {
    width: 100%;
    padding: 3.5rem 2.5rem;
    background-color: ${colors.colorAccent};

    @media (min-width: 768px) {
      padding: 3.5rem 7.5rem;
    }

    &__title {
      width: 100%;

      h2 {
        ${H2White};
      }
    }

    &__content {
      width: 100%;

      p {
        ${B1White};
      }
    }

    &__links {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .image {
    position: relative;
    width: 100%;
    margin-top: -15rem;
    margin-bottom: 1rem;
    z-index: 1;
  }
`

const StyledLink = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    width: calc(33.33333333%);
    margin-bottom: 2.7rem;
  }

  a {
    ${B1White};
    text-decoration: underline;
    text-transform: uppercase;

    &:hover {
      color: ${colors.colorPrimary};
    }
  }
`

export default MoreInformation
