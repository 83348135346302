import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PageIntro from "../components/theProject/PageIntro"
import Milestones from "../components/theProject/Milestones"
import ContentLinks from "../components/theProject/ContentLinks"
import MoreInformation from "../components/theProject/MoreInformation"

const TheProject = props => {
  const { seoInfo } = props.data
  const pageIntro = props?.data?.pageIntro?.template?.theProject
  const milestones = props?.data?.milestones?.template?.theProject
  const contentLinks = props?.data?.contentLinks?.template?.theProject
  const moreInformation = props?.data?.moreInformation?.template?.theProject

  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <PageIntro data={pageIntro} />
      <Milestones data={milestones} />
      <ContentLinks data={contentLinks} />
      <MoreInformation data={moreInformation} />
    </Layout>
  )
}

export const projectQuery = graphql`
  query projectTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    pageIntro: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_TheProject {
          theProject {
            pageIntroTitle
            pageIntroContent
            pageIntroImageTop {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
            pageIntroImageBottom {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }
        }
      }
    }

    milestones: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_TheProject {
          theProject {
            projectMilestonesTitle
            projectMilestonesSteps {
              title
              content
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1500)
                  }
                }
              }
            }
          }
        }
      }
    }

    contentLinks: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_TheProject {
          theProject {
            contentLinksTitle
            contentLinksContent
            contentLinksLinks {
              text
              page {
                ... on WpPage {
                  id
                  slug
                  uri
                }
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1500)
                  }
                }
              }
            }
          }
        }
      }
    }

    moreInformation: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_TheProject {
          theProject {
            moreInformationTitle
            moreInformationContent
            moreInformationLinks {
              title
              url
            }
            moreInformationImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TheProject
