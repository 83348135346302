import React, { useEffect } from "react"
import styled from "styled-components"
import { H1GrapePurple, medWrapper } from "../../styles/helpers"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import letsGo from "../../images/lets-go.png"
import Wysiwyg from "./Wysiwyg"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const PageIntro = ({ data }) => {
  useEffect(() => {
    gsap
      .timeline({
        repeat: -1,
        yoyo: true,
      })
      .fromTo(
        `#page-intro .content__images--icon`,
        {
          x: 4,
          rotate: "3deg",
        },
        {
          ease: "none",
          x: -4,
          rotate: "-3deg",
          duration: 1,
        }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#page-intro`,
          markers: false,
          start: "top 15%",
          toggleActions: "play none none none",
        },
      })
      .add("start")
      .fromTo(
        `#page-intro .content__images--top`,
        {
          autoAlpha: 0,
          x: -100,
        },
        {
          autoAlpha: 1,
          ease: "power2.out",
          x: 0,
          duration: 2,
          stagger: {
            each: 0.4,
          },
        },
        "start"
      )
      .fromTo(
        `#page-intro .content__images--bot`,
        {
          autoAlpha: 0,
          x: -100,
        },
        {
          autoAlpha: 1,
          ease: "power2.out",
          x: 0,
          duration: 2,
          stagger: {
            each: 0.4,
          },
        },
        "start"
      )
  }, [])

  const imgTopDisplay = getImage(
    data.pageIntroImageTop.localFile.childImageSharp.gatsbyImageData
  )
  const imgTopAlt = data.pageIntroImageTop.altText

  const imgBotDisplay = getImage(
    data.pageIntroImageBottom.localFile.childImageSharp.gatsbyImageData
  )
  const imgBotAlt = data.pageIntroImageBottom.altText

  return (
    <SectionStyled id="page-intro">
      <div className="wrapper">
        <div className="title">
          <h1 dangerouslySetInnerHTML={{ __html: data.pageIntroTitle }} />
        </div>
        <div className="content">
          <div className="content__images">
            <div className="content__images--top">
              <GatsbyImage
                image={imgTopDisplay}
                alt={imgTopAlt}
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
              />
            </div>
            <div className="content__images--icon">
              <img src={letsGo} alt="Lets go!" />
            </div>
            <div className="content__images--bot">
              <GatsbyImage
                image={imgBotDisplay}
                alt={imgBotAlt}
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
          <Wysiwyg data={data.pageIntroContent} />
        </div>
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  margin-bottom: 6.5rem;

  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    margin-bottom: 6rem;

    h1 {
      ${H1GrapePurple};
      max-width: 75rem;
      margin-bottom: 0;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .push-div {
      margin-bottom: 5rem;
    }

    &__images {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(25% - 2rem);
        margin-right: 2rem;
      }

      &--icon {
        max-width: 17rem;
        margin: 2rem auto;
      }
    }
  }
`

export default PageIntro
