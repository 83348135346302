import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1White,
  H1GrapePurple,
  H3DarkGrey,
  medWrapper,
} from "../../styles/helpers"
import dotGraphic from "../../images/dot-graphic-green.png"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const ContentLinks = ({ data }) => {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#content-links`,
          markers: false,
          start: "top 30%",
          toggleActions: "play none none none",
        },
      })
      .fromTo(
        `#content-links .link-box`,
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          autoAlpha: 1,
          ease: "power2.out",
          y: 0,
          duration: 2,
          stagger: {
            each: 0.4,
          },
        }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#content-links`,
          markers: false,
          start: "top 50%",
          end: "bottom 0%",
          scrub: true,
          invalidateOnRefresh: true, // to make it responsive
        },
      })
      .fromTo(
        `#content-links .dot-graphic`,
        {
          y: 200,
          ease: "none",
        },
        { y: -75, ease: "none" }
      )
  }, [])
  return (
    <StyledSection id="content-links">
      <div className="wrapper">
        <div className="title">
          <h2>{data.contentLinksTitle}</h2>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.contentLinksContent }}
        />

        <div className="links">
          {data.contentLinksLinks.map((link, index) => {
            const imgDisplay = getImage(
              link.image.localFile.childImageSharp.gatsbyImageData
            )
            const imgAlt = link.image.altText
            return (
              <LinkBox
                key={index}
                topend={index === 1}
                botstart={index === 2}
                className="link-box"
              >
                <Link to={`${link.page.uri}`}>
                  <div className="link-image">
                    <GatsbyImage
                      image={imgDisplay}
                      alt={imgAlt}
                      layout="fullWidth"
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                  <div className="link-content">
                    <p>{link.text}</p>
                    <p>Learn More</p>
                  </div>
                </Link>
              </LinkBox>
            )
          })}
        </div>
      </div>
      <div className="dot-graphic">
        <img src={dotGraphic} alt="" />
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  position: relative;

  .dot-graphic {
    position: absolute;
    top: 25%;
    right: 0;
    width: 21.3rem;
    z-index: 1;
  }
  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;

    h2 {
      ${H1GrapePurple};
    }
  }

  .content {
    width: 100%;
    margin-bottom: 5rem;

    p {
      ${H3DarkGrey};
      font-weight: 300;
    }
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const LinkBox = styled.div`
  display: block;
  position: relative;
  width: calc((100% / 1) - 0.75rem);
  margin-bottom: 4.6rem;
  z-index: 100;

  @media (min-width: 768px) {
    width: calc((100% / 3) - 0.75rem);
    ${props => props =>
      props.topend ? "margin-right:33.333333%; margin-left: 1.5rem;" : null};
    ${props => props =>
      props.botstart ? "margin-left: 33.333333%; margin-right:1.5rem;" : null};
  }

  .link-content {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    transition: all 0.3s ease-in;
    background-color: rgba(124, 29, 57, 0.8);
    text-align: center;

    p {
      ${B1White};
      margin: 0;
      text-transform: uppercase;

      &:first-of-type {
        font-weight: 600;
      }

      &:last-of-type {
        font-weight: 300;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    .link-content {
      background-color: rgba(29, 160, 136, 0.8);
    }
  }
`

export default ContentLinks
